import Vue from 'vue'
import $ from 'jquery'

let ivShare = Vue.component('iv-share', {
    // The parent app passes in the current route.url and route.title
    props: ['url', 'text', 'iconsOrText'],

    methods: {
        emailHref: function () {
            return 'mailto:?body=' + encodeURIComponent(this.$props.url) + '&subject=' + encodeURIComponent(this.plainText)
        },
        popup: function (popup) {
            switch (popup) {
                case 'facebook':
                    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.$props.url) + '&t=' + this.$props.text + '', '', 'toolbar=0, status=0, width=900, height=500')
                    break
                case 'linkedin':
                    window.open('https://www.linkedin.com/cws/share?url=' + encodeURIComponent(this.$props.url) + '&token=&isFramed=true', 'linkedin', 'toolbar=no,width=550,height=550')
                    break
                case 'twitter':
                    window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.$props.text) + '&url=' + encodeURIComponent(this.$props.url) + ' @IndexVentures', '', 'toolbar=0, status=0, width=650, height=360')
                    break
            }
        },
    },

    computed: {
        plainText: function () {
            return $('<p>' + this.$props.text + '</p>').text()
        },
    },

    template: '#iv-share',
})

export {ivShare}